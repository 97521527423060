import { useEffect, useState } from "react";
import { removeAccents } from "../functions/removeAccents";
import { mData } from "../data/globals";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "../App.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ClientList({ client, takeClient }) {
  const [list, setList] = useState("");

  const filterAddresses = () => {
    var txt = client.VALUE.trim();
    if (txt === "") {
      return [];
    } else {
      txt = removeAccents(txt).toLowerCase();
      let tempAddr = mData.take("CLIENTS").filter((o) => {
        if (o.NOMCLI !== "") {
          let name = removeAccents(o.NOMCLI).toLowerCase();

          if (txt.includes(" ")) {
            let parts = txt.split(" ");
            return (
              parts.filter((b) => {
                return name.includes(b.toLowerCase());
              }).length === parts.length
            );
          } else {
            return name.includes(txt);
          }
        }
      });
      tempAddr.length > 200 && (tempAddr.length = 200);
      return tempAddr;
    }
  };

  const assignClient = (o) => {
    console.log(o);
    mData.save({ CLIENT: o });
    mData.save({ TARSUC: Number(o.TARCLI) });
    localStorage.setItem("CLI", JSON.stringify(o));
    takeClient(o);
  };

  useEffect(() => {
    if (client.VALUE && client.VALUE.trim() != "") {
      setList("loading");
      setTimeout(() => setList(filterAddresses()), 500);
    }

    // if (search.CODCAT) {
    //   window.history.pushState(null, "", "?category=" + search.CODCAT);
    // } else if (search.VALUE) {
    //   window.history.pushState(null, "", "?q=" + search.VALUE);
    // }

    // if (search.type) {
    //   console.log(mData.take(["TARSUC", "PAISUC"]));
    //   axios
    //     .post(
    //       process.env.REACT_APP_API_URL,
    //       JSON.stringify({ ...search, ...mData.take(["TARSUC", "PAISUC"]) }),
    //       {
    //         headers: {
    //           "Content-Type":
    //             "application/x-www-form-urlencoded; charset=UTF-8",
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       console.log(response.data);
    //       setTimeout(() => {
    //         setList(response.data.results);
    //       }, 1);
    //     });
    // } else {
    // }
  }, [client]);

  return (
    <div className="thebox">
      <div
        style={{
          width: "100%",
          backgroundColor: "#DEDEDE",
          boxSizing: "content-box",
        }}
      >
        <div
          style={{
            margin: 10,
            borderRadius: 10,
            backgroundColor: "#405394",
            color: "white",
            padding: 10,
            fontWeight: "bolder",
          }}
        >
          CLIENTES
        </div>
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
        }}
      >
        {list === "loading" ? (
          <div className="App-resultMsg">Buscando...</div>
        ) : Array.isArray(list) && list.length == 0 ? (
          <div className="App-resultMsg">Sin resultados</div>
        ) : Array.isArray(list) && list.length > 0 ? (
          <Grid
            container
            spacing={5}
            padding={2}
            columns={{ xs: 1, sm: 8, md: 20 }}
          >
            {list.map((o, index) => (
              <Grid key={index} item xs={1} sm={4} md={4}>
                <Item
                  onClick={() => {
                    assignClient(o);
                  }}
                  style={{
                    textAlign: "left",
                    position: "relative",
                    cursor: "pointer",
                    height: "100%",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      fontWeight: "bolder",
                      fontSize: 18,
                    }}
                  >
                    <b style={{ fontSize: 14 }}>[{o.CODCLI}]</b>
                    <br />
                    {o.NOMCLI}
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                      padding: 10,
                      paddingBottom: 0,
                    }}
                  >
                    <b>Tel: {o.TELCLI}</b>
                    <br />
                    {o.DOMCLI}
                  </div>
                </Item>
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
