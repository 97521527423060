import { useState, useEffect } from "react";
import Header from "./Header";
import ItemList from "./ItemList";
import ClientList from "./ClientList";
import ModalInfo from "./ModalInfo";
import ModalMsg from "./ModalMsg";
import ModalCart from "./ModalCart";
import { mData, catList } from "../data/globals";

import { IoCart } from "react-icons/io5";

import axios from "axios";
import "../App.css";
import OrderList from "./OrderList";
import ModalSearch from "./ModalSearch";

export default function Main() {
  const [search, setSearch] = useState({});
  const [client, setClient] = useState({});
  const [sendClient, setSendClient] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalMsgData, setModalMsgData] = useState({});
  const [modalCardData, setModalCardData] = useState({});
  const [mainData, setMainData] = useState({});
  const [page, setPage] = useState({ index: 0, code: {} });
  const [order, setOrder] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    let data = localStorage.getItem("CLI");
    console.log("data: ", data);
    if (data && data.length > 0) {
      data = JSON.parse(data);
      if (data.CODCLI) {
        console.log(data);
        mData.save({ CLIENT: data });
        mData.save({ TARSUC: Number(data.TARCLI) });
        setSendClient(data);
      }
    }
    callNewList();
  }, []);

  const closeModal = () => {
    setModalData({});
  };

  const openCart = (value) => {
    console.log("valueCart", value);
    setModalData({
      title: value.NOMART,
      item: value,
      accept: saveQuantity,
      acceptTitle: "GUARDAR",
    });
  };

  const openModalLogin = () => {
    setModalData({
      login: true,
      title: "LOGIN",
      accept: startLogin,
      acceptTitle: "ENTRAR",
    });
  };

  const acceptOrder = () => {};

  const openCartList = async (orderData = null) => {
    console.log("orderData 1 ", orderData);
    let allOk = true;
    if (orderData) {
      setModalMsgData({ message: "Cargando datos...", cancel: false });
      allOk = orderData = await mData.findOrderByNum(orderData);
      if (!allOk) {
        setModalMsgData({ message: "Pedido no existe" });
      }
    }

    console.log("orderData 2 ", orderData);
    if (allOk) {
      setModalCardData({
        visible: true,
        title: "PEDIDO",
        accept: acceptOrder,
        acceptTitle: "SIGUIENTE",
        cancelTitle: "CERRAR",
        orderData,
        start: Math.random(),
      });
    }
  };

  const saveQuantity = ({ item, quantity }) => {
    console.log(item);
    console.log(quantity);
    if (quantity > 0) {
      //var index = catList.findIndex((obj) => obj.CODART == item.CODART);
      //catList[index]["CANOR0"] = quantity;
      mData.addOrder({ item, quantity });
      closeModal();
    } else {
      setModalMsgData({ message: "Ingrese una cifra válida" });
    }
  };

  const startLogin = ({ user, password }) => {
    if (user.trim() == "") {
      setModalMsgData({ message: "Ingrese código de usuario" });
    } else if (password.trim() == "") {
      setModalMsgData({ message: "Ingrese contraseña" });
    } else {
      setModalMsgData({ message: "Validando", cancel: false });
      axios
        .post(
          process.env.REACT_APP_API_URL,
          JSON.stringify({
            type: "LOGIN",
            user: user.replaceAll(" ", ""),
            password: password.replaceAll(" ", ""),
          }),
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            console.log(response.data);
            if (response.data.CLI) {
              console.log("entro como cliente");
              setMainData({ ...mainData, ...response.data.CLI });

              mData.save({ CLIENT: response.data.CLI });
              mData.save({ TARSUC: Number(response.data.CLI.TARCLI) });
              takeClient(response.data.CLI);
            } else if (response.data.USU) {
              console.log("entro como usuario");
              mData.save(response.data.USU);
              setMainData({ ...mainData, ...response.data.USU });
              localStorage.setItem("USU", JSON.stringify(response.data.USU));
              mData.findClients();
            }

            setModalMsgData({});
            closeModal();
          } else {
            setModalMsgData({
              title: "Error",
              message: "Credenciales incorrectas",
            });
          }
        });
      console.log("login start");
    }
  };

  const takeClient = (o) => {
    localStorage.setItem("CLI", JSON.stringify(o));
    setSendClient(o);
    setPage({ ...page, index: 0 });
    setTimeout(() => {
      callNewList();
    }, 1000);
  };

  const finder = (_value = page.code) => {
    console.log("value search: ", _value);
    if (_value.type === "CAT") {
      setPage({ index: 0, code: _value });
    }
    switch (page.index) {
      case 0:
        setSearch({ ..._value, start: Math.random() });
        break;
      case 1:
        setClient({ ..._value, start: Math.random() });
        break;
      case 2:
        setOrder({ ..._value, type: "ORDERS", start: Math.random() });
        break;
    }
  };

  const changePage = (d) => {
    setPage({ ...page, index: d });
  };

  const pages = () => {
    switch (page.index) {
      case 0:
        return (
          <ItemList
            sendClient={sendClient}
            search={search}
            finder={finder}
            openCart={openCart}
            mainData={mainData}
          />
        );
      case 1:
        return <ClientList client={client} takeClient={takeClient} />;
      case 2:
        return <OrderList order={order} openCartList={openCartList} />;
    }
  };

  const callNewList = () => {
    setSearch({
      type: "NEW",
      title: "NUEVOS",
      LIMIT: 20,
      start: Math.random(),
    });
  };

  const logout = () => {
    mData.logout();
    setSendClient(false);
    localStorage.clear();

    axios
      .post(
        process.env.REACT_APP_API_URL,
        JSON.stringify({
          type: "main",
          branch: 1,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        mData.save(response.data.branch);
        callNewList();
        if (catList.length == 0) {
          response.data.categories.map((o) => {
            o["title"] = o.NOMCAT;
            o["children"] = response.data.categories.filter(
              (v) => v.PADCAT == o.CODCAT
            );
            catList.push(o);
          });
        }
        setTimeout(() => {
          setMainData(true);
        }, 500);
      });
  };

  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        finder={finder}
        openSearch={() => {
          setOpenSearch(true);
        }}
        changePage={changePage}
        openModalLogin={openModalLogin}
        sendClient={sendClient}
        callNewList={callNewList}
        logout={logout}
      />
      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
        }}
      >
        {pages()}
      </div>
      {sendClient && (
        <div className="bubble">
          <IoCart
            size={50}
            color="white"
            onClick={() => {
              openCartList();
              //openCart(o);
            }}
          />
        </div>
      )}
      <ModalSearch
        visible={openSearch}
        finder={finder}
        close={() => {
          setOpenSearch(false);
        }}
      />
      <ModalInfo data={modalData} />
      <ModalMsg data={modalMsgData} />
      <ModalCart
        data={modalCardData}
        sendClient={sendClient}
        setModalMsgData={setModalMsgData}
      />
    </div>
  );
}
