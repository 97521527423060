import { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ModalMsg(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (Object.keys(props.data).length > 0) {
      setOpen(true);
      const { title = "", message = "", cancel = true } = props.data;

      const dd = {
        title: title,
        message: message,
        cancel: cancel,
      };
      console.log(dd);
      setData(dd);
    } else {
      setOpen(false);
    }
  }, [props.data]);

  return (
    <Dialog
      open={open}
      onClose={data.cancel ? closeModal : () => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {data.title && (
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", fontWeight: "bolder" }}
        >
          {data.title}
        </DialogTitle>
      )}

      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontSize: 20, textAlign: "center" }}
        >
          {data.message}
        </DialogContentText>
      </DialogContent>
      {data.cancel && (
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <div
            onClick={closeModal}
            className="App-button"
            style={{ backgroundColor: "gray" }}
          >
            Cerrar
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
}
