import { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { mData } from "../data/globals";

export default function ModalInfo(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [qty, setQty] = useState("");
  const quantityRef = useRef(null);
  const userRef = useRef(null);
  const passwordRef = useRef(null);
  const [cart, setCart] = useState([1]);

  const closeModal = () => {
    setOpen(false);
  };

  const loadCard = async () => {
    // mData.take("CART").map((r) => {
    //   <tr>
    //     <td>{r.CODART}</td>
    //     <td>{r.NOMART}</td>
    //     <td>{r.CANART}</td>
    //     <td>{r.PREART}</td>
    //     <td>{r.TOTART}</td>
    //   </tr>;
    // })
    //let agua = await mData.take("CART");
    //console.log("rrr", agua);
    return cart.map((o, index) => <div>fdfdf</div>);
  };

  useEffect(() => {
    if (Object.keys(props.data).length > 0) {
      setOpen(true);
      const {
        cart = false,
        login = false,
        title = "",
        message = "",
        cancel = closeModal,
        cancelTitle = "CANCELAR",
        accept = false,
        acceptTitle = "",
        item = false,
      } = props.data;

      const dd = {
        cart: cart,
        login: login,
        title: title,
        message: message,
        cancel: cancel,
        cancelTitle: cancelTitle,
        accept: accept,
        acceptTitle: acceptTitle,
        item: item,
      };
      setQty(dd.item.CANOR0);
      console.log("modal");
      console.log(dd);
      setData(dd);
    } else {
      setOpen(false);
    }
  }, [props.data]);
  //const []

  return (
    <Dialog
      open={open}
      onClose={data.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {data.title && (
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", fontWeight: "bolder" }}
        >
          {data.title}
        </DialogTitle>
      )}

      <DialogContent>
        {data.item && (
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontSize: 20, textAlign: "center" }}
          >
            <>
              <span>Cantidad </span>
              <input
                ref={quantityRef}
                className="App-input"
                type="number"
                value={qty ?? ""}
                onChange={(e) => {
                  setQty(e.target.value);
                }}
                autoFocus
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    console.log(data.item);
                    data.accept({
                      item: data.item,
                      quantity: quantityRef.current.value,
                    });
                  }
                }}
              />
            </>
          </DialogContentText>
        )}
        {data.login && (
          <>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: 20, padding: 3 }}
            >
              Email:
              <br />
              <input
                ref={userRef}
                className="App-input"
                autoFocus
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    data.accept({
                      user:
                        userRef.current == null ? "" : userRef.current.value,
                      password:
                        passwordRef.current == null
                          ? ""
                          : passwordRef.current.value,
                    });
                  }
                }}
              />
            </DialogContentText>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: 20, padding: 3 }}
            >
              Contraseña: <br />
              <input
                ref={passwordRef}
                className="App-input"
                type="password"
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    data.accept({
                      user:
                        userRef.current == null ? "" : userRef.current.value,
                      password:
                        passwordRef.current == null
                          ? ""
                          : passwordRef.current.value,
                    });
                  }
                }}
              />
            </DialogContentText>
          </>
        )}
        {data.cart && (
          <>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontSize: 20, padding: 3 }}
            >
              {loadCard()}
            </DialogContentText>
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 20,
        }}
      >
        <div
          onClick={data.cancel}
          className="App-button"
          style={{ backgroundColor: "gray" }}
        >
          {data.cancelTitle}
        </div>
        {data.accept && (
          <div
            onClick={() => {
              data.item
                ? data.accept({
                    item: data.item,
                    quantity: quantityRef.current.value,
                  })
                : data.accept({
                    /*quantity:
                      quantityRef.current == null
                        ? 0
                        : quantityRef.current.value,*/
                    user: userRef.current == null ? "" : userRef.current.value,
                    password:
                      passwordRef.current == null
                        ? ""
                        : passwordRef.current.value,
                  });
            }}
            className="App-button"
          >
            {data.acceptTitle}
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}
