import { useEffect, useState } from "react";
import logo from "../assets/logo.webp";
import minilogo from "../assets/minilogo.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//import InboxIcon from "@mui/icons-material/MoveToInbox";
//import MailIcon from "@mui/icons-material/Mail";

import {
  IoPersonCircleSharp,
  IoSearch,
  IoCall,
  IoHomeSharp,
  IoMenu,
  IoCart,
  IoPeople,
  IoCreate,
  IoClose,
} from "react-icons/io5";
import { catList, mData } from "../data/globals";
import "../App.css";
import axios from "axios";

function UserMenu() {}

export default function Header({
  finder,
  openModalLogin,
  openSearch,
  changePage,
  sendClient,
  callNewList,
  logout,
}) {
  const [anchorEl, setAnchorEl] = useState([]);
  const [userEl, setUserEl] = useState(false);
  const [message, setMessage] = useState("");
  const [menu, setMenu] = useState(false);
  const [client, setClient] = useState(false);
  const [searchType, setSearchType] = useState(0);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }} //anchor === 'top' || anchor === 'bottom' ? 'auto' : 250
      role="presentation"
      onClick={() => {
        setMenu(false);
      }}
      onKeyDown={() => {
        setMenu(false);
      }}
    >
      <div
        style={{ width: "100%", height: 20, backgroundColor: "green" }}
      ></div>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {}}>
            <ListItemIcon>
              <IoHomeSharp size={30} />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <Divider />
        {catList
          .filter((z) => z.PADCAT == "")
          .map((o, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => {
                  if (searchType != 0) {
                    setSearchType(0);
                  }
                  finder({ type: "CAT", ...o });
                }}
              >
                <ListItemText primary={o.NOMCAT} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      {/* <Divider />
      <List>
        ["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                /*index % 2 === 0 ? <InboxIcon /> : <MailIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        
      </List> */}
    </Box>
  );

  const handleClick = (event, _index) => {
    if (anchorEl.length == 0) {
      let _anchor = [];
      _anchor[_index] = event.currentTarget;
      setAnchorEl(_anchor);
    }
  };

  const changePageValue = (value) => {
    changePage(value);
    setSearchType(value);
  };

  const handleClickUser = (event) => {
    setUserEl(event.currentTarget);
  };

  const handleClose = () => {
    if (searchType != 0) {
      setSearchType(0);
    }
    setAnchorEl([]);
  };

  const handleCloseUser = () => {
    if (searchType != 0) {
      setSearchType(0);
    }
    changePageValue(0);
    callNewList();
    setUserEl(false);
  };

  const handleKeyDown = (event, _pass) => {
    if ((event.key === "Enter" || _pass) && message.trim() != "") {
      finder({
        type: "SEARCH",
        title: "RESULTADOS: " + message.trim(),
        VALUE: message,
      });
    }
  };

  useEffect(() => {
    if (sendClient && sendClient.NOMCLI != undefined) {
      console.log("client: ", mData.take("CODUSU"));
      setClient({ name: sendClient.NOMCLI, code: sendClient.CODCLI });
    } else {
      setClient(false);
    }
    setMessage("");
  }, [sendClient]);

  const writeUserMenu = () => {
    return (
      <Menu
        id="basic-menu"
        anchorEl={userEl}
        open={Boolean(userEl)}
        onClose={handleCloseUser}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseUser();
            logout();
          }}
        >
          Salir
        </MenuItem>
      </Menu>
    );
  };

  const callSearch = () => {
    return (
      <div style={{ position: "relative" }}>
        <IoSearch
          size={30}
          color="gray"
          onClick={(event) => {
            handleKeyDown(event, true);
          }}
          style={{ position: "absolute", left: 7, top: 5 }}
        />
        <input
          placeholder="Buscar"
          onKeyDown={handleKeyDown}
          value={message}
          onChange={handleChange}
          className="App-input"
          style={{ paddingLeft: 35, paddingRight: 35 }}
        />
        <IoClose
          size={30}
          color="gray"
          onClick={() => {
            setMessage("");
          }}
          style={{ position: "absolute", right: 7, top: 5 }}
        />
      </div>
    );
  };

  const callButtonSearch = () => {
    return (
      <div
        onClick={openSearch}
        className="Search-button"
        style={{ position: "relative" }}
      >
        Buscar Pedidos
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 5,
          height: 55,
        }}
      >
        <div style={{ flex: 1 }}>
          <img className="main-logo" src={logo} alt="Logo" />
          <img className="mini-logo" src={minilogo} alt="Logo" />
        </div>

        {/* <IoCall size={30} />
        <div>Llámanos al</div> */}
        {client && mData.take("NOMUSU") ? (
          <div style={{ padding: 5 }}>
            [{client.code}]
            <br />
            {client.name}
          </div>
        ) : client ? (
          <div style={{ padding: 5 }}>{client.name}</div>
        ) : (
          <></>
        )}
        {mData.take("NOMUSU") && (
          <IoPeople
            size={50}
            onClick={() => {
              changePageValue(1);
              //openCart(o);
            }}
          />
        )}
        {mData.take("NOMUSU") || client ? (
          <>
            <IoCreate
              size={50}
              onClick={() => {
                changePageValue(2);
                //openCart(o);
              }}
            />
            <div className="loginUser" onClick={handleClickUser}>
              {mData.take("NOMUSU") ? mData.take("NOMUSU")[0] : client.name[0]}
            </div>
            {writeUserMenu()}
          </>
        ) : (
          <IoPersonCircleSharp onClick={openModalLogin} size={50} />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#0b6400",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <IoMenu
            className="menu-mini"
            size={38}
            onClick={() => {
              setMenu(true);
            }}
            color="white"
            style={{ marginRight: 10 }}
          />
          <IoHomeSharp
            className="menu-bar"
            size={30}
            onClick={() => {}}
            color="white"
            style={{ marginRight: 10 }}
          />
          {catList
            .filter((z) => z.PADCAT == "")
            .map((o, index) => {
              return (
                <div
                  key={index}
                  className="App-menu-unit menu-bar"
                  onClick={(event) => {
                    if (o.children.length > 0) {
                      handleClick(event, index);
                    } else {
                      if (searchType != 0) {
                        setSearchType(0);
                      }
                      finder({ type: "CAT", ...o });
                    }
                  }}
                >
                  {o.NOMCAT}
                  {o.children.length > 0 && (
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl[index]}
                      open={Boolean(anchorEl[index])}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {o.children.map((v) => (
                        <MenuItem
                          key={v.CODCAT}
                          onClick={() => {
                            handleClose();
                            finder({
                              type: "CAT",
                              NOMPAD: o.NOMCAT,
                              ...v,
                            });
                          }}
                        >
                          {v.NOMCAT}
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </div>
              );
            })}
        </div>
        {searchType === 2 ? callButtonSearch() : callSearch()}
      </div>
      <Drawer
        anchor="left"
        open={menu}
        onClose={() => {
          setMenu(false);
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
}
