import React from "react";

export default function ImageBox(props) {
  const { image, size, quantity = null } = props;

  const getOpacity = () => {
    return quantity > 0 || quantity == null ? 1 : 0.5;
  };
  return (
    <div
      style={{
        aspectRatio: "4/3",
        width: size ?? "90%",
        backgroundColor: "white",
        borderRadius: 20,
        overflow: "hidden",
        border: `solid 3px ${image.ACT > 0 ? "#0b6400" : "#D3D3D3"}`,
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      {image.IMG != "" && (
        <img
          src={`${process.env.REACT_APP_API_IMAGE}mini/${image.CODART}.${image.IMGART}`}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            overflow: "hidden",
            opacity: 0,
            transition: "opacity 0.5s",
          }}
          loading="lazy"
          onLoad={({ currentTarget }) => {
            currentTarget.style.opacity = getOpacity();
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = process.env.REACT_APP_API_IMAGE + "nopic2.gif";
            currentTarget.style.opacity = getOpacity();
          }}
        />
      )}
      {quantity}
    </div>
  );
}
