import { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import {
  IoPersonCircleSharp,
  IoSearch,
  IoCall,
  IoHomeSharp,
  IoCart,
  IoClose,
} from "react-icons/io5";
import { mData, maxWidth, medWidth } from "../data/globals";
import ImageBox from "./ImageBox";
import ModalConfirm from "./ModalConfirm";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

var trigger = false;

export default function ModalCart(props) {
  const { data, sendClient, setModalMsgData } = props;
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState({});
  const [extra, setExtra] = useState({
    NETORD: 0,
    IMPORD: 0,
    TOTORD: 0,
    OBS: "",
  });
  const [list, setList] = useState([]);
  const [size, setSize] = useState(getWindowDimensions());

  const checkVertical = () => {
    return size.width < size.height ? true : false;
  };

  const sendOrder = async () => {
    let resDeleteOrder = await mData.sendOrder(extra.OBS);

    if (resDeleteOrder.data) {
      finalClose("El pedido fue enviado");
    }
  };

  const askConfirm = () => {
    let dataConfirm = {
      visible: true,
      close: () => {
        setConfirm({});
      },
      title: "¿Desea enviar este pedido?",
      name: extra.NOMCLI,
      width: size.width,
      confirmFunc: sendOrder,
    };
    setConfirm(dataConfirm);
  };

  const askDelete = (type, data) => {
    console.log(type, data);
    let dataConfirm = {
      visible: true,
      close: () => {
        askDelete("close");
      },
    };
    switch (type) {
      case "order":
        dataConfirm = {
          ...dataConfirm,
          title: "¿Desea eliminar este pedido?",
          name: extra.NOMCLI,
          width: size.width,
          deleteFunc: () => {
            deleteOrder();
          },
        };
        break;
      case "row":
        dataConfirm = {
          ...dataConfirm,
          title: "Desea la fila de",
          name: data.o.CODART,
          width: "auto",
          deleteFunc: () => {
            deleteOrder(data.index);
          },
        };
        break;
      default:
        dataConfirm = {};
        break;
    }

    setConfirm(dataConfirm);
  };

  const deleteOrder = async (index = false) => {
    setConfirm({ visible: true, type: "loading" });
    let resDeleteOrder = await mData.deleteOrder(index);

    if (resDeleteOrder.data) {
      if (resDeleteOrder.data.DELETEORDER || !index) {
        finalClose("El pedido fue eliminado");
      } else {
        mData.findOrder();
      }
    }
  };

  const finalClose = (title) => {
    setList([]);
    setExtra({ NETORD: 0, IMPORD: 0, TOTORD: 0, OBS: "" });
    //setOpen(false);
    setConfirm({
      visible: true,
      title,
      close: () => {
        setConfirm({});
        setOpen(false);
      },
      closeTxt: "Cerrar",
    });
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: `${checkVertical() ? "90" : "70"}%`,
      height: `${checkVertical() ? "90" : "70"}%`,
      display: "flex",
      flexDirection: "column", //checkVertical() ? "column" : "row",
      padding: 0,
      borderRadius: 10,
    },
  };

  const sendData = async (data) => {
    await mData.updateRow(data);
    //console.log("resDeleteOrder: ", resDeleteOrder);
  };

  const saveRow = (data, directSave = false) => {
    if (trigger) {
      clearTimeout(trigger);
    }
    if (directSave) {
      sendData(data);
    } else {
      trigger = setTimeout(() => {
        sendData(data);
      }, 1000);
    }
  };

  const writeInput = (index, key, data) => {
    if (
      ((mData.take("ORDUS1") > 0 && key === "PREOR0") || key === "CANOR0") &&
      extra.EDIT
    ) {
      return (
        <input
          type="number"
          value={key === "PREOR0" ? data[key].toFixed(2) : data[key]}
          style={{ width: 70, textAlign: "right", fontSize: 16 }}
          onChange={(e) => {
            if (e.target.value > 0) {
              saveRow({
                index,
                key,
                value: e.target.value,
                LINOR0: data.LINOR0,
                ARTOR0: data.CODART,
              });
            }
            changeValue(index, key, e.target.value);
            console.log(e.target.value);
          }}
        />
      );
    } else {
      return key === "PREOR0" ? data[key].toFixed(2) : data[key];
    }
  };

  const changeValue = async (index, key, quantity) => {
    await mData.changeOrderValue(index, key, quantity);
    writeList();
  };

  const writeHead = () => {
    return size.width > maxWidth ? (
      <tr>
        <th></th>
        <th></th>
        <th>Código</th>
        <th>Descripción</th>
        <th>Cantidad</th>
        <th>Precio</th>
        <th>Total</th>
      </tr>
    ) : size.width > medWidth ? (
      <tr>
        <th></th>
        <th></th>
        <th>Cantidad</th>
        <th>Precio</th>
        <th>Total</th>
      </tr>
    ) : (
      <tr>
        <th></th>
        <th>Cantidad</th>
        <th>Precio</th>
        <th>Total</th>
      </tr>
    );
  };

  const colorRow = (index) => {
    return index % 2 == 0 ? "white" : "#E9E9E9";
  };

  const writeRows = () => {
    return list.map((o, index) =>
      size.width > maxWidth ? (
        <tr key={index} style={{ backgroundColor: colorRow(index) }}>
          <td align="center">
            {extra.EDIT && (
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: 15,
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                }}
              >
                <IoClose
                  size={30}
                  color="white"
                  onClick={() => {
                    askDelete("row", { o, index });
                  }}
                />
              </div>
            )}
          </td>
          <td width={100}>
            <ImageBox image={o} size={"80%"} />
          </td>
          <td>{o.CODART}</td>
          <td>{o.NOMOR0}</td>
          <td align="center">{writeInput(index, "CANOR0", o)}</td>
          <td align="right">{writeInput(index, "PREOR0", o)}</td>
          <td align="right" style={{ paddingRight: 10 }}>
            {o.TOTOR0.toFixed(2)}
          </td>
        </tr>
      ) : size.width > medWidth ? (
        <>
          <tr key={index} style={{ backgroundColor: colorRow(index) }}>
            <td align="center" rowSpan={2}>
              {extra.EDIT && (
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: 15,
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                >
                  <IoClose
                    size={30}
                    color="white"
                    onClick={() => {
                      askDelete("row", { o, index });
                    }}
                  />
                </div>
              )}
            </td>
            <td width={100} rowSpan={2}>
              <ImageBox image={o} size={"80%"} />
            </td>
            <td colSpan={3}>
              {o.CODART}
              <br />
              {o.NOMOR0}
            </td>
          </tr>
          <tr key={index + "b"} style={{ backgroundColor: colorRow(index) }}>
            <td align="center">{writeInput(index, "CANOR0", o)}</td>
            <td align="right">{writeInput(index, "PREOR0", o)}</td>
            <td align="right" style={{ paddingRight: 10 }}>
              {o.TOTOR0.toFixed(2)}
            </td>
          </tr>
        </>
      ) : (
        <>
          <tr key={index} style={{ backgroundColor: colorRow(index) }}>
            <td align="center" rowSpan={2}>
              {extra.EDIT && (
                <div
                  style={{
                    backgroundColor: "red",
                    borderRadius: 15,
                    width: 30,
                    height: 30,
                    cursor: "pointer",
                  }}
                >
                  <IoClose
                    size={30}
                    color="white"
                    onClick={() => {
                      askDelete("row", { o, index });
                    }}
                  />
                </div>
              )}
            </td>
            <td width={100}>
              <ImageBox image={o} size={"80%"} />
            </td>
            <td colSpan={2}>
              {o.CODART}
              <br />
              {o.NOMOR0}
            </td>
          </tr>
          <tr key={index + "b"} style={{ backgroundColor: colorRow(index) }}>
            <td align="center">{writeInput(index, "CANOR0", o)}</td>
            <td align="right">{writeInput(index, "PREOR0", o)}</td>
            <td align="right" style={{ paddingRight: 10 }}>
              {o.TOTOR0.toFixed(2)}
            </td>
          </tr>
        </>
      )
    );
  };

  const writeList = (fixData = null) => {
    console.log("fixData: ", fixData);
    let clientData;
    if (fixData) {
      clientData = fixData;
    } else {
      clientData = mData.take("CLIENT");
    }
    console.log("clientData: ", clientData);
    if (
      clientData &&
      ((clientData["ORDER"] && clientData["ORDER"]["ITEMS"]) ||
        clientData.items)
    ) {
      let tempList = [];
      let bucket1 = clientData.items ?? clientData["ORDER"]["ITEMS"];
      bucket1.forEach((i) => {
        tempList.push({
          CODART: i.ARTOR0,
          NOMOR0: i.NOMOR0,
          LINOR0: i.LINOR0,
          IMGART: i.IMGART,
          CANOR0: i.CANOR0,
          PREOR0: i.PREOR0,
          TOTOR0: i.TOTOR0,
        });
      });
      setList([...tempList]);
    } else {
      setList([]);
    }

    setExtra({
      ...extra,
      CODCLI: clientData.CODCLI ?? clientData.CLIORD,
      NOMCLI: clientData.NOMCLI ?? clientData.NOMORD,
      TELCLI: clientData.TELCLI ?? clientData.TELORD,
      DOMCLI: clientData.DOMCLI ?? clientData.DOMORD,
      NETORD:
        clientData["ORDER"] && clientData["ORDER"].NETORD
          ? clientData["ORDER"].NETORD
          : clientData.NETORD ?? 0,
      IMPORD:
        clientData["ORDER"] && clientData["ORDER"].IMPORD
          ? clientData["ORDER"].IMPORD
          : clientData.IMPORD ?? 0,
      TOTORD:
        clientData["ORDER"] && clientData["ORDER"].TOTORD
          ? clientData["ORDER"].TOTORD
          : clientData.TOTORD ?? 0,
      EDIT: fixData ? false : true,
    });
  };

  useEffect(() => {
    function handleResize() {
      setSize(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log(sendClient);
    if (data.visible) {
      if (data.orderData) {
        setModalMsgData({});
      }
      writeList(data.orderData);
    }
    setOpen(data.visible);
  }, [data.start]);

  return (
    <>
      <Modal
        isOpen={open}
        //onAfterOpen={afterOpenModal}
        //onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        //contentLabel="Example Modal"
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 5,
              top: 5,
              backgroundColor: "red",
              borderRadius: 15,
              width: 30,
              height: 30,
              cursor: "pointer",
            }}
          >
            <IoClose
              size={30}
              color="white"
              onClick={() => {
                setOpen(false);
              }}
            />
          </div>
          <div style={{ backgroundColor: "#C3C3C3", padding: 10 }}>
            <div>
              Cliente: ({extra.CODCLI}) {extra.NOMCLI}
            </div>
            <div>Teléfono: {extra.TELCLI}</div>
            <div>Dirección: {extra.DOMCLI}</div>
          </div>
          <div style={{ flex: 1, overflow: "auto", height: 100 }}>
            <table width="100%" border="0" cellPadding={3} cellSpacing={0}>
              <thead
                style={{
                  backgroundColor: "#0b6400",
                  color: "white",
                }}
              >
                {writeHead()}
              </thead>
              <tbody>{writeRows()}</tbody>
            </table>
          </div>
          {list.length > 0 && (
            <div style={{ backgroundColor: "#C3C3C3", padding: 10 }}>
              <div style={{ padding: 3, display: "flex" }}>
                <input
                  placeholder="Observaciones"
                  disabled={!extra.EDIT}
                  style={{ flex: 1 }}
                  value={extra.OBS}
                  onChange={(e) => {
                    setExtra({ ...extra, OBS: e.target.value });
                  }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <div style={{ width: 100, textAlign: "right" }}>
                  {extra.NETORD.toFixed(2)}
                </div>
                Neto
              </div>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <div style={{ width: 100, textAlign: "right" }}>
                  {extra.IMPORD.toFixed(2)}
                </div>
                ITBMS
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  fontWeight: "bolder",
                  fontSize: 20,
                }}
              >
                <div style={{ width: 100, textAlign: "right" }}>
                  {extra.TOTORD.toFixed(2)}
                </div>
                Total
              </div>
              {extra.EDIT && (
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <div
                    className="App-button"
                    style={{ margin: 5 }}
                    onClick={askConfirm}
                  >
                    Enviar
                  </div>
                  <div
                    className="App-button"
                    style={{ backgroundColor: "red", margin: 5 }}
                    onClick={() => {
                      askDelete("order");
                    }}
                  >
                    Eliminar
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
      <ModalConfirm data={confirm} />
    </>
  );
}
