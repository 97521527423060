export const getCurrentDate = (addTime = false) => {
  var today = new Date();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  let finalDate = [today.getFullYear(), month, day].join("-");

  if (addTime) {
    let hour = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();

    hour = hour < 10 ? "0" + hour : hour;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    finalDate += " " + [hour, minutes, seconds].join(":");
  }

  return finalDate;
};
