import { useEffect, useState } from "react";
import "./App.css";
import Main from "./components/Main";
import Loading from "./components/Loading";
import axios from "axios";
import { catList, mData } from "./data/globals";

function App() {
  const [loading, setLoading] = useState(true);
  const [mainData, setMainData] = useState(false);
  useEffect(() => {
    document.title = "FED Import S.A.";

    let data = localStorage.getItem("USU");
    console.log("data: ", data);
    if (data && data.length > 0) {
      data = JSON.parse(data);
      if (data.CODUSU) {
        console.log(data);
        mData.save(data);
        mData.findClients();
      }
    }

    axios
      .post(
        process.env.REACT_APP_API_URL,
        JSON.stringify({
          type: "main",
          branch: 1,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        console.log("-------------");
        console.log(response.data);
        mData.save(response.data.branch);
        if (catList.length == 0) {
          response.data.categories.map((o) => {
            o["title"] = o.NOMCAT;
            o["children"] = response.data.categories.filter(
              (v) => v.PADCAT == o.CODCAT
            );
            catList.push(o);
          });
        }
        setTimeout(() => {
          setMainData(true);
        }, 500);
        //console.log(catList);
      });
  }, []);
  return <div>{mainData ? <Main /> : <Loading />}</div>;
}

export default App;
