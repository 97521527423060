import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { mData, catList } from "../data/globals";
import { useSearchParams } from "react-router-dom";
import imageError from "../assets/no-image.png";
import "../App.css";

import {
  IoPersonCircleSharp,
  IoSearch,
  IoCall,
  IoHomeSharp,
  IoCart,
} from "react-icons/io5";
import ImageBox from "./ImageBox";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ItemList({ search, openCart, finder, sendClient }) {
  const [list, setList] = useState("");
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    console.log("......");
    console.log(search);
    console.log("......");
    if (search.CODCAT || search.VALUE) {
      console.log(search);
      setList("loading");
    }
    if (search.CODCAT) {
      window.history.pushState(null, "", "?category=" + search.CODCAT);
    } else if (search.VALUE) {
      window.history.pushState(null, "", "?q=" + search.VALUE);
    }

    console.log("-----");
    console.log(search.type);
    if (search.type) {
      console.log(mData.take(["TARSUC", "PAISUC"]));
      let _order = mData.take("CLIENT");
      console.log(_order);
      if (_order && _order.ORDER && _order.ORDER.ORDEN) {
        _order = _order.ORDER.ORDEN;
      } else {
        _order = false;
      }
      console.log("order: " + _order);
      let payLoadSearch = {
        ...search,
        ...mData.take(["TARSUC", "PAISUC"]),
        ORDER: _order,
      };

      console.log("payloadSearch: ", payLoadSearch);

      axios
        .post(process.env.REACT_APP_API_URL, JSON.stringify(payLoadSearch), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          console.log(response.data);
          setTimeout(() => {
            setList(response.data.results);
          }, 1);
        });
    } else {
    }
  }, [search.start]);

  useEffect(() => {
    if (sendClient) {
      setShowCart(true);
    } else {
      setShowCart(false);
    }
  }, [sendClient]);

  const findFather = () => {
    let _f = catList.filter((k) => k.CODCAT == search.PADCAT);
    if (_f.length == 1) {
      finder({ type: "CAT", ..._f[0] });
    }
  };

  return (
    <div className="thebox">
      {search.title ? (
        <div
          style={{
            width: "100%",
            backgroundColor: "#DEDEDE",
            boxSizing: "content-box",
          }}
        >
          <div
            style={{
              margin: 10,
              borderRadius: 10,
              backgroundColor: "#063300",
              color: "white",
              padding: 10,
              fontWeight: "bolder",
            }}
          >
            {search.PADCAT != "" && search.PADCAT != undefined ? (
              <>
                <span style={{ cursor: "pointer" }} onClick={findFather}>
                  {search.NOMPAD}
                </span>
                {" > "} {search.title}
              </>
            ) : (
              search.title
            )}
          </div>
          {search.CODCAT && search.children.length > 0 && (
            <div
              style={{
                position: "relative",
                textAlign: "center",
                lineHeight: 2.5,
                paddingBottom: 10,
              }}
            >
              {search.children.map((h) => {
                return (
                  <st key={h.CODCAT}>
                    <span
                      className="subcat"
                      onClick={() => {
                        finder({
                          type: "CAT",
                          NOMPAD: search.NOMCAT,
                          ...h,
                        });
                      }}
                    >
                      &nbsp;{h.NOMCAT}&nbsp;
                    </span>{" "}
                  </st>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
        }}
      >
        {list === "loading" ? (
          <div className="App-resultMsg">Buscando...</div>
        ) : Array.isArray(list) && list.length == 0 ? (
          <div className="App-resultMsg">Sin resultados</div>
        ) : Array.isArray(list) && list.length > 0 ? (
          <>
            <Grid
              container
              spacing={5}
              padding={2}
              columns={{ xs: 1, sm: 8, md: 20 }}
            >
              {list.map((o, index) => (
                <Grid key={index} item xs={1} sm={4} md={4}>
                  <Item
                    style={{
                      textAlign: "left",
                      position: "relative",
                      cursor: "pointer",
                      height: "100%",
                      backgroundColor: o.ACT > 0 ? "#F3FDF2" : "#F9F9F9",
                      border:
                        o.ACT > 0 ? "solid 3px #0b6400" : "solid 0px #ffffff",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        padding: 5,
                        fontWeight: "bolder",
                      }}
                    >
                      {o.NOMART}
                    </div>
                    <ImageBox image={o} quantity={o.ACT} />
                    <div
                      style={{
                        fontSize: 18,
                        textAlign: "right",
                        padding: 10,
                        paddingBottom: 0,
                      }}
                    >
                      <b style={{ fontSize: 14 }}>[{o.CODART}]</b>
                      <br />
                      <b style={{ fontSize: 18, color: "blue" }}>
                        {o.PRELTA.toFixed(2)}
                      </b>
                      <br />
                      <b style={{ fontSize: 18 }}>Disponible: {o.ACT}</b>
                      {showCart && (
                        <div
                          style={{ position: "absolute", left: 10, bottom: 10 }}
                          onClick={() => {
                            openCart(o);
                          }}
                        >
                          <IoCart size={40} />
                          {o.CANOR0 && (
                            <div
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                                backgroundColor: "red",
                                color: "white",
                                paddingLeft: 5,
                                paddingRight: 5,
                                borderRadius: 30,
                              }}
                            >
                              {o.CANOR0}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </Item>
                </Grid>
              ))}
            </Grid>
            <div style={{ height: 100 }}></div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
