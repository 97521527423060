import { useEffect, useState } from "react";
import { mData } from "../data/globals";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "../App.css";
import axios from "axios";
import { checkDate } from "../functions/checkDate";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function OrderList({ order, openCartList }) {
  const [list, setList] = useState("");
  const [agent, setAgent] = useState(false);

  useEffect(() => {
    console.log("-----");
    console.log(order.type);
    if (order.type && order.date1) {
      setList("loading");
      let payloadOrder = {
        ...order,
        userType: mData.take("CODUSU") > 0 ? "AGEORD" : "CLIORD",
        COD:
          mData.take("CODUSU") > 0
            ? mData.take("CODUSU")
            : mData.take("CLIENT")["CODCLI"],
      };

      console.log("payloadOrder: ", payloadOrder);

      axios
        .post(process.env.REACT_APP_API_URL, JSON.stringify(payloadOrder), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((response) => {
          console.log(response.data);
          setTimeout(() => {
            setList(response.data.results);
          }, 1);
        });
    }
  }, [order.start]);

  useEffect(() => {
    if (mData.take("CODUSU") > 0) {
      setAgent(true);
    } else {
      setAgent(false);
    }
  }, [mData.take("CODUSU")]);

  const getTotal = (key) => {
    let value = 0;
    list.forEach((e) => {
      if (!isNaN(e[key])) {
        value += e[key];
      }
    });
    return value.toFixed(2);
  };

  return (
    <div className="thebox">
      <div
        style={{
          width: "100%",
          backgroundColor: "#DEDEDE",
          boxSizing: "content-box",
        }}
      >
        <div
          style={{
            margin: 10,
            borderRadius: 10,
            backgroundColor: "#405394",
            color: "white",
            padding: 10,
            fontWeight: "bolder",
          }}
        >
          PEDIDOS
          {agent &&
            Array.isArray(list) &&
            ` Cantidad: ${list.length} Neto: ${getTotal(
              "NETORD"
            )} ITBMS: ${getTotal("IMPORD")} Total: ${getTotal("TOTORD")}`}
        </div>
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
        }}
      >
        {list === "loading" ? (
          <div className="App-resultMsg">Buscando...</div>
        ) : Array.isArray(list) && list.length == 0 ? (
          <div className="App-resultMsg">Sin resultados</div>
        ) : Array.isArray(list) && list.length > 0 ? (
          <Grid
            container
            spacing={5}
            padding={2}
            columns={{ xs: 1, sm: 8, md: 20 }}
          >
            {list.map((o, index) => (
              <Grid key={index} item xs={1} sm={4} md={4}>
                <Item
                  onClick={() => {
                    openCartList({ ORDEN: o.ORDEN, CLIORD: o.CLIORD });
                  }}
                  style={{
                    textAlign: "left",
                    position: "relative",
                    cursor: "pointer",
                    height: "100%",
                    backgroundColor: o.PASORD > 0 ? "#F9F9F9" : "#FFE3A2",
                  }}
                >
                  <div
                    style={{
                      padding: 5,
                      fontWeight: "bolder",
                    }}
                  >
                    {agent && (
                      <>
                        <b style={{ fontSize: 16 }}>{o.NOMORD}</b>
                        <br />
                      </>
                    )}
                    <b style={{ fontSize: 14 }}>Orden: {o.ORDEN}</b>
                    <br />
                    Estado: {o.PASORD === 0 ? "Pendiente" : "Enviado"}
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      padding: 10,
                      paddingBottom: 0,
                    }}
                  >
                    <b>Fecha:</b> {checkDate(o.FECHA)}
                    <div
                      style={{
                        fontWeight: "bolder",
                        fontSize: 18,
                      }}
                    >
                      Monto: B/. {o.TOTORD.toFixed(2)}
                    </div>
                  </div>
                </Item>
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
