import { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import {
  IoPersonCircleSharp,
  IoSearch,
  IoCall,
  IoHomeSharp,
  IoCart,
  IoClose,
} from "react-icons/io5";
import { mData } from "../data/globals";
import ImageBox from "./ImageBox";
import { Spinner } from "react-activity";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function ModalConfirm(props) {
  const {
    type,
    visible,
    title,
    name,
    close,
    closeTxt = "Cancelar",
    deleteFunc,
    confirmFunc,
    width,
  } = props.data;
  const [size, setSize] = useState(getWindowDimensions());

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: width < 500 ? "80%" : "auto",
      display: "flex",
      flexDirection: "column", //checkVertical() ? "column" : "row",
      padding: 10,
      borderRadius: 10,
    },
  };

  return (
    <Modal
      isOpen={visible}
      //onAfterOpen={afterOpenModal}
      //onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      //contentLabel="Example Modal"
    >
      {type === "loading" ? (
        <Spinner />
      ) : (
        <>
          {title && <div style={{ textAlign: "center" }}>{title}</div>}
          {name && <div style={{ textAlign: "center" }}>{name}</div>}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            {close && (
              <div
                className="App-button"
                style={{ backgroundColor: "gray", margin: 5 }}
                onClick={close}
              >
                {closeTxt}
              </div>
            )}
            {deleteFunc && (
              <div
                className="App-button"
                style={{ backgroundColor: "red", margin: 5 }}
                onClick={deleteFunc}
              >
                Eliminar
              </div>
            )}
            {confirmFunc && (
              <div
                className="App-button"
                style={{ backgroundColor: "green", margin: 5 }}
                onClick={confirmFunc}
              >
                Enviar
              </div>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
