import { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import {
  IoPersonCircleSharp,
  IoSearch,
  IoCall,
  IoHomeSharp,
  IoCart,
  IoClose,
} from "react-icons/io5";
import { mData } from "../data/globals";
import ImageBox from "./ImageBox";
import { Spinner } from "react-activity";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function ModalSearch(props) {
  const { visible, finder, close, width } = props;
  const [agent, setAgent] = useState(false);
  const [name, setName] = useState({ name: "name", value: "", error: false });
  const [date1, setDate1] = useState({
    name: "date1",
    value: "",
    error: false,
  });
  const [date2, setDate2] = useState({
    name: "date2",
    value: "",
    error: false,
  });
  const [msg, setMsg] = useState("");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: width < 500 ? "80%" : "auto",
      display: "flex",
      flexDirection: "column", //checkVertical() ? "column" : "row",
      padding: 10,
      borderRadius: 10,
    },
  };

  const sendData = () => {
    let allData = {};
    let dataList = { name, date1, date2 };
    if (date1.value != "" && date2.value != "") {
      setMsg("");
      //setName({...name, error:false})
      setDate1({ ...date1, error: false });
      setDate2({ ...date2, error: false });
      Object.keys(dataList).forEach((e) => {
        if (dataList[e].value != "") {
          allData[e] = dataList[e].value;
        }
      });
      console.log("alldata", allData);
      finder(allData);
      close();
    } else {
      if (date1.value === "") {
        setDate1({ ...date1, error: true });
      }
      if (date2.value === "") {
        setDate2({ ...date2, error: true });
      }
      setMsg("Complete los campos en rojo");
    }
  };

  const writeInput = (typeInput = "text", target) => {
    return (
      <div style={{ position: "relative" }}>
        <input
          type={typeInput}
          // placeholder={typeInput === "text" ? "Cliente" : ""}
          value={target.value}
          onChange={(event) => {
            switch (target.name) {
              case "date1":
                setDate1({ ...date1, value: event.target.value });
                break;
              case "date2":
                setDate2({ ...date2, value: event.target.value });
                break;
              default:
                setName({ ...name, value: event.target.value });
                break;
            }
          }}
          className="App-input"
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "94%",
            border: `solid 3px ${target.error ? "red" : "gray"}`,
          }}
        />
        <IoClose
          size={30}
          color="gray"
          onClick={() => {
            switch (target.name) {
              case "date1":
                setDate1({ ...date1, value: "", error: false });
                break;
              case "date2":
                setDate2({ ...date2, value: "", error: false });
                break;
              default:
                setName({ ...name, value: "", error: false });
                break;
            }
          }}
          style={{
            position: "absolute",
            right: target.name === "name" ? 7 : 30,
            top: 10,
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (mData.take("CODUSU") > 0) {
      setAgent(true);
    } else {
      setAgent(false);
      setName({ ...name, value: "" });
    }
  }, [mData.take("CODUSU")]);

  return (
    <Modal
      isOpen={visible}
      //onAfterOpen={afterOpenModal}
      //onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      //contentLabel="Example Modal"
    >
      {agent && (
        <>
          <b>Cliente:</b>
          {writeInput("text", name)}
        </>
      )}

      <b>Fechas:</b>
      {writeInput("date", date1)}
      {writeInput("date", date2)}
      <div style={{ textAlign: "center", color: "red", fontWeight: "bolder" }}>
        {msg}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <div
          className="App-button"
          style={{ backgroundColor: "gray", margin: 5 }}
          onClick={close}
        >
          Cancelar
        </div>

        <div
          className="App-button"
          style={{ backgroundColor: "green", margin: 5 }}
          onClick={sendData}
        >
          Buscar
        </div>
      </div>
    </Modal>
  );
}
