import React from "react";
import "../App.css";
import logo from "../assets/logo.webp";

export default function Loading() {
  return (
    <div
      className="App App-loading-container"
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img className="App-logo" src={logo} alt="Logo" />
    </div>
  );
}
