import axios from "axios";
import { getCurrentDate } from "../functions/getCurrentDate";
export var catList = [];
export var clients = [];
export const maxWidth = 700;
export const medWidth = 400;

export class GlobalData {
  constructor() {
    this.TARSUC = 3;
    this.PAISUC = "PAN";
    this.CLIENTS = [];
    this.CLIENT = {};
  }

  save(_data) {
    Object.keys(_data).map((o) => {
      return (this[o] = _data[o]);
    });

    if (_data.hasOwnProperty("CLIENT")) {
      console.log("start order");
      this.findOrder();
    }
  }

  take(_data) {
    try {
      let _return;
      if (Array.isArray(_data)) {
        _return = {};
        _data.map((o) => {
          return (_return[o] = this[o]);
        });
      } else {
        _return = this[_data];
      }
      //console.log(_return);
      return _return;
    } catch (error) {
      console.log("take error", error);
    }
    return false;
  }

  findClients() {
    axios
      .post(
        process.env.REACT_APP_API_URL,
        JSON.stringify({
          type: "CLIENTS",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.clients) {
          this.CLIENTS = response.data.clients;
        }
      });
  }

  async deleteOrder(index = false) {
    try {
      let findDeleteOrder = {
        type: index ? "DELETEROW" : "DELETEORDER",
        ORDER: this.CLIENT.ORDER.ORDEN,
        CLIORD: this.CLIENT.CODCLI,
        LINE: index ? this.CLIENT.ORDER.ITEMS[index].LINOR0 : "",
        CODART: index ? this.CLIENT.ORDER.ITEMS[index].ARTOR0 : "",
      };
      console.log("findDeleteOrder: ", findDeleteOrder);
      const resDeleteOrder = await axios.post(
        process.env.REACT_APP_API_URL,
        JSON.stringify(findDeleteOrder),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log("resDeleteOrder", resDeleteOrder);
      if (resDeleteOrder.data) {
        if (index) {
        } else {
          this.CLIENT["ORDER"] = [];
        }
      }
      return resDeleteOrder;
    } catch (error) {
      console.log("deleteOrder error: ", error);
    }
    return false;
  }

  findOrder() {
    let findOrderPayload = {
      type: "ORDER",
      CLIORD: this.CLIENT.CODCLI,
      AGEORD: this.CODUSU,
      TARCLI: this.CLIENT.TARCLI,
      PAI: this.PAISUC,
    };
    console.log("findOrderPayload: ", findOrderPayload);
    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(findOrderPayload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => {
        console.log(response.data);
        this.writeOrder(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async changeOrderValue(index, type, value) {
    console.log(index, type, value);
    this.CLIENT["ORDER"]["ITEMS"][index][type] = Number(value);
    this.CLIENT["ORDER"]["ITEMS"][index]["TOTOR0"] =
      value *
      this.CLIENT["ORDER"]["ITEMS"][index][
        type === "PREOR0" ? "CANOR0" : "PREOR0"
      ];
    /*switch (type) {
      case "CANOR0":
        this.CLIENT["ORDER"]["ITEMS"][index]["TOTOR0"] =
          value * this.CLIENT["ORDER"]["ITEMS"][index][type==="PREOR0"?"CANOR0":"PREOR0"];
        break;
      case "PREOR0":
        this.CLIENT["ORDER"]["ITEMS"][index]["TOTOR0"] =
          value * this.CLIENT["ORDER"]["ITEMS"][index]["CANOR0"];
        break;
      default:
        break;
    }*/
    console.log("value", this.CLIENT["ORDER"]["ITEMS"][index]);
    this.sumNumbers();
    return true;
  }

  sumNumbers(item = false, quantity = false) {
    let results = {
      NET: 0,
      IMP: 0,
      TOT: 0,
    };

    let newPass = true;

    if (this.CLIENT["ORDER"] && this.CLIENT["ORDER"]["ITEMS"]) {
      this.CLIENT["ORDER"]["ITEMS"].forEach((i) => {
        var net = i.PREOR0 * i.CANOR0;
        var imp = net * (i.IMPOR0 / 100);
        if (item && quantity && i.ARTOR0 === item.CODART) {
          newPass = false;
          net = item.PRELTA * quantity;
          imp = net * (item.IMPARI / 100);
        }
        results.NET += net;
        results.IMP += imp;
        results.TOT += net + imp;
      });
    }

    if (item && quantity && newPass) {
      var net = item.PRELTA * quantity;
      var imp = net * (item.IMPARI / 100);
      results.NET += net;
      results.IMP += imp;
      results.TOT += net + imp;
    }

    if (this.CLIENT["ORDER"]) {
      this.CLIENT.ORDER.NETORD = results.NET;
      this.CLIENT.ORDER.IMPORD = results.IMP;
      this.CLIENT.ORDER.TOTORD = results.TOT;
    }

    return results;
  }

  addOrder(data) {
    let theOrder = this.CLIENT["ORDER"];
    if (theOrder && theOrder.ORDEN) {
      theOrder = theOrder.ORDEN;
    } else {
      theOrder = false;
    }

    let totals = this.sumNumbers(data.item, data.quantity);
    console.log("totals: ", totals);
    let addOrderPayload = {
      type: "ADDITEM",
      ORDER: theOrder,
      SERIE: this.CODSUC,
      FECHA: getCurrentDate(true),
      CODCLI: this.CLIENT.CODCLI,
      NOMCLI: this.CLIENT.NOMCLI,
      DOMCLI: this.CLIENT.DOMCLI,
      TELCLI: this.CLIENT.TELCLI,
      CODAGE: this.CODUSU,
      NOMAGE: this.NOMUSU,
      ALM: this.ALMSUC,
      PAI: this.PAISUC,
      ITEM: data.item,
      QUANTITY: data.quantity,
      NETORD: theOrder ? this.CLIENT.ORDER.NETORD : totals.NET,
      IMPORD: theOrder ? this.CLIENT.ORDER.IMPORD : totals.IMP,
      TOTORD: theOrder ? this.CLIENT.ORDER.TOTORD : totals.TOT,
    };

    console.log("addOrderPayload: ", addOrderPayload);

    axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(addOrderPayload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => {
        this.writeOrder(response.data);
      });
  }

  writeOrder(_data) {
    try {
      //this.CLIENT["ORDER"] = false;
      console.log("writeOrder", _data);
      if (_data.order.length > 0) {
        let _order = _data.order[0];
        _order["ITEMS"] = _data.items;
        this.CLIENT["ORDER"] = _order;
      }
      console.log(this.CLIENT["ORDER"]);
    } catch (e) {
      console.log(e);
    }
  }

  async updateRow(_data) {
    try {
      let updateRowPayload = {
        type: "UPDATEITEM",
        ORDER: this.CLIENT["ORDER"].ORDEN,
        LINOR0: _data.LINOR0,
        ARTOR0: _data.ARTOR0,
        KEY: _data.key,
        VALUE: _data.value,
      };
      console.log("updateRowPayload: ", updateRowPayload);
      const resUpdateRow = await axios.post(
        process.env.REACT_APP_API_URL,
        JSON.stringify(updateRowPayload),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log("resUpdateRow: ", resUpdateRow);
      if (resUpdateRow.data) {
      }
      return resUpdateRow;
    } catch (error) {
      console.log("deleteOrder error: ", error);
    }
    return false;
  }

  async sendOrder(obs) {
    try {
      let sendOrderOrder = {
        type: "SENDORDER",
        ORDER: this.CLIENT.ORDER.ORDEN,
        CLIORD: this.CLIENT.CODCLI,
        obs,
      };
      console.log("sendOrderOrder: ", sendOrderOrder);
      const resSendOrder = await axios.post(
        process.env.REACT_APP_API_URL,
        JSON.stringify(sendOrderOrder),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log("resSendOrder: ", resSendOrder);
      if (resSendOrder.data) {
        this.CLIENT["ORDER"] = [];
        return resSendOrder;
      }
    } catch (error) {
      console.log("sendOrder error: ", error);
    }
    return false;
  }

  async findOrderByNum(orderNum) {
    try {
      let findOrderByNumPayload = {
        type: "ORDERBYNUM",
        ...orderNum,
      };
      console.log("findOrderByNumPayload: ", findOrderByNumPayload);
      const resFindOrderByNum = await axios.post(
        process.env.REACT_APP_API_URL,
        JSON.stringify(findOrderByNumPayload),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }
      );
      console.log("resFindOrderByNum", resFindOrderByNum);
      if (resFindOrderByNum.data && resFindOrderByNum.data.success) {
        return {
          ...resFindOrderByNum.data.order[0],
          items: resFindOrderByNum.data.items,
        };
      }
    } catch (error) {
      console.log("findOrderByNum error: ", error);
    }
    return null;
  }

  logout() {
    console.log("logout");
    Object.keys(this).map((o) => {
      //console.log(o);
      return delete this[o];
    });
    //console.log("objects: ", Object.keys(this));
  }
}

export const mData = new GlobalData();
